import { Injectable } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ProdGenApi } from './apiService/prodgen.api'
import { take } from 'rxjs/operators';

@Injectable()
export class BrowserAuthenticationService {

    constructor(private router: Router, private route: ActivatedRoute, private service: ProdGenApi) {
			//console.log("***Authenticate Construct***");

	}


    AuthenticatePage(): boolean {
		if (sessionStorage.getItem("APIKey") && sessionStorage.getItem("userAccessKey")) {

			// console.log("Authenticated");
			// console.log("login url:");

			let v_URL = localStorage.getItem("LoginURL");
			if (v_URL == null || v_URL == "") {
				return true;
			}
			else {

				localStorage.setItem("LoginURL", "");
				window.location.href = v_URL;
				window.location.reload();
				return false;
			}


		} else {
			let v_URL = window.location.href;
			//look for naviate parameter
			this.route.queryParams.pipe(take(1)).subscribe(queryParams => {

				if (queryParams['NaviateID'] != null) {

					let email: string = queryParams['NaviateID'];

					

					this.service.isNaviateUser(email).subscribe(res => {

						if (res == true) {

							this.service.getNaviateRequestURL(email).subscribe(url => {


								if (url != null && url != "") {
									//strip naviate parameter

									let v_ParamName = `NaviateID=${email}`;
									
									let v_HasAnd = v_URL.includes("world");

									if (v_HasAnd == true) {
										v_URL = v_URL.replace(`&${v_ParamName}`, '');
									}
									else {
										v_URL = v_URL.replace(v_ParamName, '');
									}

									localStorage.setItem("LoginURL", v_URL);

									window.location.href = url;
								}
								else {
									console.log("URL Request Failed");
									this.ProcessLogin();
								}

							});

						}
						else {

							this.ProcessLogin();
						}

					});

				}
				else {
					localStorage.setItem("LoginURL", v_URL);

					this.ProcessLogin();
				}

			});


		}
            
	}

	ProcessLogin() {

			//check partner override data

			let v_Email: string = "";
			//check for email param
			this.route.queryParams.subscribe(queryParams => {
				//console.log("Query params found");
				if (queryParams['email'] != null) {
					v_Email = queryParams['email'];

				}
			});

			//check storage
			if (v_Email == "") {
				//console.log("Checking local storage");
				v_Email = localStorage.getItem("userEmail");
			}

			//console.log("Email:");
			//console.log(v_Email);

			//check against naviate

		
			if (v_Email != "") {

				this.service.getHasPartnerSSO_Override(v_Email).subscribe(res => {

			//		//console.log("Result:");
			//		//console.log(res);

					if (res != null) {
						window.location.href = res.link;
					}
					else {
						this.router.navigate(['/login']);
					}
				}, err => {
					this.router.navigate(['/login']);
				})
			}
			else {
				this.router.navigate(['/login']);
			}

			return false;

		
	}
    

}