import { Injectable } from '@angular/core';
import { ProdGenApi } from '../apiService/prodgen.api';
import { AppComponent } from '../app.component';

@Injectable()
export class ContentUrlService {
    constructor(private pinnacleService: ProdGenApi) { }

    getPeakUrlPath(pinnacleLinkUrl: string) {
        if (pinnacleLinkUrl && pinnacleLinkUrl.length > 0)
        {
            const queryParams = this.extractQueryParams(pinnacleLinkUrl);
            
            if (pinnacleLinkUrl.includes('learningcenter/series?courseId')) {
                const courseId = queryParams.get('courseId');
                if (courseId) {
                    return `/content/${courseId}`;
                }
            }
            else if (pinnacleLinkUrl.includes('learningcenter/series?learningPathId')) {
                const learningPathId = queryParams.get('learningPathId');
                if (learningPathId) {
                    return `/content/${learningPathId}`;
                }
            }
            else if (pinnacleLinkUrl.includes('cheatsheetviewer') ||
                pinnacleLinkUrl.includes('workflowviewer') ||
                pinnacleLinkUrl.includes('videoviewer')) {
                const contentId = queryParams.get('id');
                if (contentId) {
                    return `/content/${contentId}`;
                }
            }
        }

        return null;
    }

    routeToPeakUrlFromPinnacleUrl(pinnacleLinkUrl: string) {
        if (pinnacleLinkUrl && pinnacleLinkUrl.includes('learningcenter/courseviewer?enrollmentId')) {
            // Special case for enrollments (get the course id for the enrollment)
            this.routeToPeakCourseUrlFromEnrollmentUrl(pinnacleLinkUrl);
        }
        else {
            let peakUrlPath = this.getPeakUrlPath(pinnacleLinkUrl);
            try {
                // send v2 bearer token & lang values to Peak to bypass authorization
                // for tenants with peak experience enabled
                AppComponent.thisFromOutside.openPeak(
                    '_self', 
                    {
                        bt: ProdGenApi.getAPIV2AccessKey().bearerToken,
                        lang: this.pinnacleService.getCurrentLanguage(),
                        peakUrlPath: peakUrlPath || ''
                    });
                sessionStorage.clear();
            } catch {}
        }       
    }

    routeToPeakCourseUrlFromEnrollmentUrl(enrollmentUrl: string) {
        if (enrollmentUrl) {
            const queryParams = this.extractQueryParams(enrollmentUrl);
            const enrollId = queryParams.get('enrollmentId');
            if (enrollId) {
                this.pinnacleService.GetEnrollment(enrollId).subscribe(res => {
                    if (res && res.courseId) {
                        try {
                            AppComponent.thisFromOutside.openPeak(
                                '_self', 
                                {
                                    bt: ProdGenApi.getAPIV2AccessKey().bearerToken,
                                    lang: this.pinnacleService.getCurrentLanguage(),
                                    peakUrlPath: `/content/${res.courseId}`
                                });
                            sessionStorage.clear();
                        } catch {}
                    }
                });
            }
        }
    }

    extractQueryParams = (url: string): Map<string, string> | null => {
        if (!url) {
          return null;
        }
      
        let queryParamsString = '';
        if (url.includes('?')) {
          queryParamsString = url.split('?')[1];
        } else {
          queryParamsString = url;
        }
      
        const queryParams = new Map<string, string>();
        if (queryParamsString) {
          queryParamsString.split('&').forEach((param) => {
            const [key, value] = param.split('=');
            queryParams.set(decodeURIComponent(key), decodeURIComponent(value));
          });
        }
      
        return queryParams;
      }
}